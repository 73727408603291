import axios from 'axios';
import { APP_SET } from './App';
import { API_AUTH, API_CHECK_TOKEN } from '../../config/'

export const AUTH_SET = 'AUTH_SET';
export const AUTH_SET_PENDING = 'AUTH_SET_PENDING';
export const AUTH_SET_FULFILLED = 'AUTH_SET_FULFILLED';
export const AUTH_SET_REJECTED = 'AUTH_SET_REJECTED';


export const AUTH_CHECK_TOKEN = 'AUTH_CHECK_TOKEN';
export const AUTH_CHECK_TOKEN_PENDING = 'AUTH_CHECK_TOKEN_PENDING';
export const AUTH_CHECK_TOKEN_FULFILLED = 'AUTH_CHECK_TOKEN_FULFILLED';
export const AUTH_CHECK_TOKEN_REJECTED = 'AUTH_CHECK_TOKEN_REJECTED';

export const AUTH_CLEAR_TOKEN = 'AUTH_CLEAR_TOKEN';

export let setAuth = (options = {}) => {
	return (dispatch, getState) => {
		let opts = getData();

		dispatch({
			type: AUTH_SET,
			payload: axios.post(
				API_AUTH,
				{...options, hash: opts.hash},
			).then(res => {
				let appData = res.data.data.appData;
				if(opts.hash !== appData.hash) {
					localStorage.setItem('stDataHash', appData.hash);
					localStorage.setItem('stOptions', JSON.stringify(appData.data));
					dispatch({
						type: APP_SET,
						data: { dataHash: appData.hash, ...appData.data }
					});
				} else {
					dispatch({
						type: APP_SET,
						data: { dataHash: opts.hash, ...JSON.parse(opts.options) }
					});
				}

				return res;
			}).catch(err => {
				console.error(err);
				throw err;
			})
		});
	};

};


export let checkToken = (token) => {
	return (dispatch, getState) => {
		let opts = getData();

		dispatch({
			type: AUTH_CHECK_TOKEN,
			payload: axios.post(
				API_CHECK_TOKEN,
				{token: token, hash: opts.hash}
			).then(res => {
				let appData = res.data.data.appData;
				if(opts.hash !== appData.hash) {
					localStorage.setItem('stDataHash', appData.hash);
					localStorage.setItem('stOptions', JSON.stringify(appData.data));
					dispatch({
						type: APP_SET,
						data: { dataHash: appData.hash, ...appData.data }
					});
				} else {
					dispatch({
						type: APP_SET,
						data: { dataHash: opts.hash, ...JSON.parse(opts.options) }
					});
				}

				return res;
			}).catch(err => {
				console.error(err);
				throw err;
			})
		});
	};
};


export let clearToken = () => {
	return {
		type: AUTH_CLEAR_TOKEN,
	};
};


function getData() {
	return {
		hash: localStorage.getItem('stDataHash'),
		options: localStorage.getItem('stOptions'),
	}
}


