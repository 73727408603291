import {
	CHARTING_HYDRATE,
	CHARTING_REDO,
	CHARTING_RESET,
	CHARTING_SAVE_DIAGNOSTICS,
	CHARTING_SAVE_DRAWING,
	CHARTING_SAVE_SHAPES,
	CHARTING_SAVE_SYMBOLS,
	CHARTING_SAVE_TEXT,
	CHARTING_SAVE_TREATMENTS,
	CHARTING_SET_OPTION,
	CHARTING_SET_VALUE,
	CHARTING_SET_VISIBILITY,
	CHARTING_UNDO,
} from '../../actions/Charting/ActiveChart';
import { cloneDeep, merge } from 'lodash';



const defaultState = {
	Species: null,
	PocketDepths: {},
	PlaqueIndex: {},
	CalculusIndex: {},
	GingivalIndex: {},
	GingivalRecession: {},
	GingivalEnlargement: {},
	MissingTeeth: [],
	ActiveIndex: 0,
	Drawings: {
		occlusal: [],
		buccal: [],
	},
	Text: {
		occlusal: [],
		buccal: [],
	},
	Shapes: {
		occlusal: [],
		buccal: [],
	},
	Diagnostics: {
		occlusal: {},
		buccal: {},
		charting: {},
	},
	DiagnosticsMap: {},
	Treatments: {
		occlusal: {},
		buccal: {},
		charting: {},
	},
	TreatmentsMap: {},
	Visibility: {
		Adult: { buccal: true, occlusal: true, charting: true },
		Deciduous: { buccal: false, occlusal: false, charting: false },
		Diagnostics: { buccal: true, occlusal: true, charting: true },
		Treatments: { buccal: true, occlusal: true, charting: true },
		Text: { buccal: true, occlusal: true, charting: true },
		Drawings: { buccal: true, occlusal: true, charting: true },
		Shapes: { buccal: true, occlusal: true, charting: true },
		SoftTissue: { occlusal: true },
		Probing: { occlusal: true },
		BoneView: { occlusal: false },
	},
	Info: {
		notes: '',
		referringVet: '',
		weight: '',
		weightUnit: 'lb',
	},
	Images: {
		buccal: null,
		occlusal: null,
	},
	undo: [],
	undoKey: 0,
	extras: {},
	v: 2,
	options: {
		abnormalPocketDepth: 4,
	},
};



export default function(state = defaultState, action) {
	switch(action.type) {
		case CHARTING_SET_VALUE:
			return setChartingValue(state, action);
		case CHARTING_SET_OPTION:
			return setOption(state, action);
		case CHARTING_SAVE_DRAWING:
			return saveUserData('Drawings', state, action);
		case CHARTING_SAVE_TEXT:
			return saveUserData('Text', state, action);
		case CHARTING_SAVE_SHAPES:
			return saveUserData('Shapes', state, action);
		case CHARTING_SAVE_SYMBOLS:
			return saveUserData(action.symbolType, state, action);
		case CHARTING_HYDRATE:
			return hydrate(state, action);
		case CHARTING_RESET:
			return reset(action.data || {});
		case CHARTING_SET_VISIBILITY:
			return setVisibility(state, action);
		case CHARTING_SAVE_DIAGNOSTICS:
			return saveDiagnostics(state, action);
		case CHARTING_SAVE_TREATMENTS:
			return saveTreatments(state, action);
		case CHARTING_UNDO:
			return undoAction(state, action);
		case CHARTING_REDO:
			return redoAction(state, action);
		default:
			return state;
	}
}


function setOption(state, action) {
	let newState = { ...state, [ action.option ]: action.value };
	let undo = makeUndoObj(state, newState);
	return { ...newState, ...undo };
}


function setChartingValue(state, action) {
	let values = state[ action.chartType ].hasOwnProperty(action.tooth)
				 ? [ ...state[ action.chartType ][ action.tooth ] ]
				 : [];

	values[ action.index ] = action.value;

	let newState = {
		...state, [ action.chartType ]: {
			...state[ action.chartType ],
			[ action.tooth ]: values,
		},
	};

	let undo = makeUndoObj(state, newState);
	return { ...newState, ...undo };
}


function saveUserData(type, state, action) {
	if(!state.Drawings.hasOwnProperty(action.canvas)) {
		return state;
	}

	let newState;


	if(action.subKey) {
		newState = {
			...state,
			[ type ]: {
				...state[ type ],
				[ action.canvas ]: {
					...state[ type ][ action.canvas ],
					[ action.subKey ]: action.data,
				},
			},
		};
	} else {
		newState = {
			...state,
			[ type ]: {
				...state[ type ],
				[ action.canvas ]: action.data,
			},
		};
	}

	let undo = makeUndoObj(state, newState);
	return { ...newState, ...undo };
}


function hydrate(state, action) {
	return merge(cloneDeep(defaultState), action.data);
}


function reset(data) {
	return { ...defaultState, ...data };
}


function setVisibility(state, action) {
	let newState = { ...state };
	let catKeys = Object.keys(state.Visibility[ action.category ]);
	let newVals = {};

	for(let i = 0; i < catKeys.length; i++) {
		newVals[ catKeys[ i ] ] = action.value;
	}

	newState.Visibility = {
		...state.Visibility,
		[ action.category ]: newVals,
	};

	return newState;
}


function saveDiagnostics(state, action) {
	let canvas = action.data.canvas;
	let diag = { ...state.Diagnostics };

	diag[ canvas ] = { ...diag[ canvas ], [ action.data.name ]: { ...action.data, objType: 'dx' } };


	let newState = { ...state, Diagnostics: diag };
	let undo = makeUndoObj(state, newState);
	return { ...newState, ...undo };
}


function saveTreatments(state, action) {
	let canvas = action.data.canvas;
	let treat = { ...state.Treatments };
	treat[ canvas ] = { ...treat[ canvas ], [ action.data.name ]: { ...action.data, objType: 'tx' } };

	let newState = { ...state, Treatments: treat };
	let undo = makeUndoObj(state, newState);

	return { ...newState, ...undo };
}


function undoAction(state, action) {
	if(!state.undo) {
		return state;
	}

	let undoKey = state.undoKey;
	if(undoKey >= state.undo.length - 1) {
		undoKey = state.undo.length - 2;
	}

	if(!state.undo[ undoKey ]) {
		return state;
	}

	//let undoState = getUndoState(state);
	let newState = { ...state, ...state.undo[ undoKey ], undoKey: undoKey - 1 };
	//newState.undo = undoState;

	return newState;
}


function redoAction(state, action) {
	if(!state.undo) {
		return state;
	}

	let undoKey = state.undoKey + 2;

	if(undoKey < 1 && state.undo.length > 1) undoKey = 1;
	if(undoKey >= state.undo.length - 1) undoKey = state.undo.length - 1;

	if(!state.undo[ undoKey ]) {
		return state;
	}

	let newState = { ...state, ...state.undo[ undoKey ], undoKey: undoKey - 1 };
	//newState.undo = undoState;

	return newState;
}

function getUndoState(state) {
	//let cloned = clone(state);
	let { undo, undoKey, Visibility, ...currentState } = state;
	return currentState;
}


function makeUndoObj(state, newState) {
	let undo = [ ...state.undo.slice(0, state.undo.length - 1), getUndoState(state), getUndoState(newState) ];
	let undoLimit = 10;

	if(undo.length > undoLimit + 1) {
		undo = [ ...undo.slice(1, undoLimit + 2) ];
	}
	return { undo: undo, undoKey: undo.length - 2 };
}
