import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import actions from '../actions';
import { AppBar, Fab, IconButton, Snackbar, Toolbar, Typography } from '@material-ui/core';
import SideNav from 'components/navigation/SideNav';
import UpdateNotifier from 'components/UpdateNotifier';
import { Fullscreen, Menu } from '@material-ui/icons';
import ErrorDialog from 'components/ErrorDialog';
import classnames from 'classnames';



const styles = {
	root: {
		marginTop: 30,
		width: '100%',
	},
	flex: {
		flex: 1,
	},
	appBarRoot: {
		boxShadow: 'none',
		minHeight: 64,
	},
	fab: {
		width: 40,
		height: 40,
	},
	overflow: {
		overflowY: 'scroll',
	},
};

function calcVH() {
	let vH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
	let app = document.getElementById("app");
	app.setAttribute("style", "height:" + vH + "px;min-height:" + vH + "px;max-height:" + vH + "px;");
	document.querySelector('.flex-item').setAttribute("style", "height:" + vH + "px;min-height:" + vH + "px;max-height:" + vH + "px;");
}



class Base extends Component {

	constructor(props) {
		super(props);
		window.addEventListener('onorientationchange', calcVH, true);
		window.addEventListener('resize', calcVH, true);
	}


	state = {
		menuOpen: false,
	};


	componentDidMount = () => {
		calcVH();
	};


	appBarStyle = () => {
		let style = {};

		if(this.props.Getters.TitleBar.color) {
			style.background = this.props.Getters.TitleBar.color
		}

		return style;
	};


	openMenu = () => {
		this.setState({ menuOpen: true });
	};


	closeMenu = () => {
		this.setState({ menuOpen: false });
	};


	closeSnackbar = () => {
		this.props.Setters.setSnackBar('', 5000, false);
	};


	toggleFullscreen = event => {
		let element = document.body;

		if(event instanceof HTMLElement) {
			element = event;
		}

		let isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;

		element.requestFullScreen = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || function() {
			return false;
		};
		document.cancelFullScreen = document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || function() {
			return false;
		};

		isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
	};


	render() {
		const { classes, forceScrollY } = this.props;
		const authed = this.props.Getters.Auth.valid;

		return (
			<div className={`flex-item print-hide`}>

				<header>
					<AppBar
						position="static"
						style={this.appBarStyle()}
						classes={{ root: classes.appBarRoot }}
					>
						<Toolbar>
							{authed &&
							<IconButton
								color="inherit"
								aria-label="Menu"
								onClick={this.openMenu}
							>
								<Menu />
							</IconButton>
							}
							<Typography variant="h6" color="inherit" className={classes.flex}>
								{this.props.Getters.TitleBar.text}
							</Typography>

							<UpdateNotifier />

							<Fab
								color="default"
								className={classes.fab}
								onClick={this.toggleFullscreen}
							>
								<Fullscreen />
							</Fab>
						</Toolbar>
					</AppBar>
				</header>

				<div className={classnames('content', forceScrollY && classes.overflow)}>

					{this.props.children}

				</div>

				<SideNav
					isOpen={this.state.menuOpen}
					closeMenu={this.closeMenu}
				/>

				<Snackbar
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					open={this.props.Getters.SnackBar.open}
					onClose={this.closeSnackbar}
					autoHideDuration={this.props.Getters.SnackBar.timeout}
					message={this.props.Getters.SnackBar.text}
				/>


				<ErrorDialog />

			</div>
		)
	}

}



/*

 Content here <br />
 <SpinnerButton />

 */

const mapDispatchToProps = dispatch => {
	return {
		Setters: {
			setTitleBarVars: values => {
				dispatch(actions.setTitleBarVars(values));
			},
			setAuth: token => {
				dispatch(actions.setAuth(token));
			},
			setChartingOptions: (options) => {
				dispatch(actions.setChartingOptions(options));
			},
			setChartingValue: (chartType, tooth, index, value) => {
				dispatch(actions.setChartingValue(chartType, tooth, index, value));
			},
			setToolState: (toolName, enabled) => {
				dispatch(actions.setToolState(toolName, enabled));
			},
			setSnackBar: (text, timeout, open = false) => {
				dispatch(actions.setSnackbar(text, timeout, open));
			},
			setActiveChartOption: (option, value) => {
				dispatch(actions.setActiveChartOption(option, value));
			},
			saveDrawing: (canvas, paths, subKey = null) => {
				dispatch(actions.saveDrawing(canvas, paths, subKey));
			},
			saveText: (canvas, text, subKey = null) => {
				dispatch(actions.saveText(canvas, text, subKey));
			},
			saveSymbols: (canvas, symbols, subKey = null) => {
				dispatch(actions.saveSymbols(canvas, symbols, subKey));
			},
		},
	}
};


const mapStateToProps = state => {
	return {
		forceScrollY: state.App.forceScrollY,
		Getters: {
			TitleBar: state.TitleBar,
			Auth: state.Auth,
			ChartingOptions: state.ChartingOptions,
			ActiveChart: state.ActiveChart,
			SnackBar: state.SnackBar,
		},
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withStyles(styles)(Base));