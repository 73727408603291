// React and router
import React from 'react';
import { BrowserRouter, Prompt, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
// Redux stuff
import { connect } from 'react-redux';
import actions from '../actions';

import Base from './Base';
import LoadingCover from '../components/LoadingCover';
import Logout from './Logout';
import Loadable from 'react-loadable';
import Faq from 'containers/Faq';


// Pages
const ChartingView = Loadable({
	loader: () => import('./ChartingView' /* webpackChunkName: "ChartingView" */),
	loading: LoadingCover, delay: 300,
});
const ClientOverview = Loadable({
	loader: () => import('./ClientOverview' /* webpackChunkName: "ClientOverview" */),
	loading: LoadingCover, delay: 300,
});
const ClientView = Loadable({
	loader: () => import('./ClientView' /* webpackChunkName: "ClientView" */),
	loading: LoadingCover, delay: 300,
});
const ClientForm = Loadable({
	loader: () => import('./ClientForm' /* webpackChunkName: "ClientForm" */),
	loading: LoadingCover, delay: 300,
});
const PatientOverview = Loadable({
	loader: () => import('./PatientOverview' /* webpackChunkName: "PatientOverview" */),
	loading: LoadingCover, delay: 300,
});
const PatientView = Loadable({
	loader: () => import('./PatientView' /* webpackChunkName: "PatientView" */),
	loading: LoadingCover, delay: 300,
});
const PatientNew = Loadable({
	loader: () => import('./PatientNew' /* webpackChunkName: "PatientNew" */),
	loading: LoadingCover, delay: 300,
});
const Home = Loadable({
	loader: () => import('./Home' /* webpackChunkName: "Home" */),
	loading: LoadingCover, delay: 300,
});
const ClinicSettings = Loadable({
	loader: () => import('./ClinicSettings' /* webpackChunkName: "ClinicSettings" */),
	loading: LoadingCover, delay: 300,
});
const ReportTemplateBuilder = Loadable({
	loader: () => import('./ReportTemplateBuilder' /* webpackChunkName: "ReportTemplateBuilder" */),
	loading: LoadingCover, delay: 300,
});
const ReportBuilder = Loadable({
	loader: () => import('./ReportBuilder' /* webpackChunkName: "ReportBuilder" */),
	loading: LoadingCover, delay: 300,
});
const Reports = Loadable({
	loader: () => import('./Reports' /* webpackChunkName: "Reports" */),
	loading: LoadingCover, delay: 300,
});
const Changelog = Loadable({
	loader: () => import('./Changelog' /* webpackChunkName: "Changelog" */),
	loading: LoadingCover, delay: 300,
});
const ForgotPassword = Loadable({
	loader: () => import('./ForgotPassword' /* webpackChunkName: "ForgotPassword" */),
	loading: LoadingCover, delay: 300,
});
const ResetPassword = Loadable({
	loader: () => import('./ResetPassword' /* webpackChunkName: "ResetPassword" */),
	loading: LoadingCover, delay: 300,
});


class Router extends React.Component {
	render() {
		return (
			<BrowserRouter>
				<Route
					render={({ location }) => (
						<Base>
							<Prompt
								when={this.props.blockRouting}
								message="You have unsaved changes - are you sure you want to leave this page?"
							/>
							<Switch location={location} key={location.key}>
								<PrivateRoute
									exact path="/" auth={this.props.Auth}
									render={(props) => (<Home {...props} />)} {...this.props}
								/>
								<PrivateRoute
									exact path="/chart" auth={this.props.Auth}
									render={(props) => (<ChartingView {...props} />)} {...this.props}
								/>
								<PrivateRoute
									exact path="/clients" auth={this.props.Auth}
									render={(props) => (<ClientOverview {...props} />)} {...this.props}
								/>
								<PrivateRoute
									exact path="/clients/new" auth={this.props.Auth}
									render={(props) => (<ClientForm {...props} />)} {...this.props}
								/>
								<PrivateRoute
									path="/clients/view/:clientID" auth={this.props.Auth}
									render={(props) => (<ClientView {...props} />)} {...this.props}
								/>
								<PrivateRoute
									exact path="/patients" auth={this.props.Auth}
									render={(props) => (<PatientOverview {...props} />)} {...this.props}
								/>
								<PrivateRoute
									path="/patients/view/:patientID" auth={this.props.Auth}
									render={(props) => (<PatientView {...props} />)} {...this.props}
								/>
								<PrivateRoute
									path="/patients/new/:clientID" auth={this.props.Auth}
									render={(props) => (<PatientNew {...props} />)} {...this.props}
								/>
								<PrivateRoute
									path="/chart/new/:patientID/:existingID?" auth={this.props.Auth}
									render={(props) => (
										<ChartingView {...props} chartingMode={'new'} />)} {...this.props}
								/>
								<PrivateRoute
									path="/chart/edit/:chartID" auth={this.props.Auth}
									render={(props) => (
										<ChartingView {...props} chartingMode={'edit'} />)} {...this.props}
								/>
								<PrivateRoute
									exact path="/reports" auth={this.props.Auth}
									render={(props) => (<Reports {...props} />)} {...this.props}
								/>
								<PrivateRoute
									path="/reports/newlayout/:layoutID?" auth={this.props.Auth}
									render={(props) => (<ReportTemplateBuilder {...props} isNew={true} />)} {...this.props}
								/>
								<PrivateRoute
									path="/reports/layout/:templateID" auth={this.props.Auth}
									render={(props) => (<ReportTemplateBuilder {...props} isNew={false} />)} {...this.props}
								/>
								<PrivateRoute
									path="/reports/newreport/:templateID/:visitID" auth={this.props.Auth}
									render={(props) => (<ReportBuilder {...props} mode="new" />)} {...this.props}
								/>
								<PrivateRoute
									path="/reports/view/:reportID" auth={this.props.Auth}
									render={(props) => (<ReportBuilder {...props} mode="edit" />)} {...this.props}
								/>
								<PrivateRoute
									exact path="/usersettings" auth={this.props.Auth}
									render={(props) => (<Home {...props} />)} {...this.props}
								/>
								<PrivateRoute
									exact path="/clinicsettings" auth={this.props.Auth}
									render={(props) => (<ClinicSettings {...props} />)} {...this.props}
								/>
								<PrivateRoute
									exact path="/faq" auth={this.props.Auth}
									render={(props) => (<Faq {...props} />)} {...this.props}
								/>
								<PrivateRoute
									exact path="/changelog" auth={this.props.Auth}
									render={(props) => (<Changelog />)} {...this.props}
								/>
								<Route
									exact path="/logout"
									render={(props) => (<Logout {...props} />)} {...this.props}
								/>
								<Route exact path="/forgotpassword" component={ForgotPassword} {...this.props} />
								<Route path="/resetpassword/:token" component={ResetPassword} {...this.props} />
							</Switch>
						</Base>
					)}
				/>
			</BrowserRouter>
		)
	}


}



const mapDispatchToProps = dispatch => {
	return {
		setAuth: token => {
			dispatch(actions.setAuth(token));
		},
	}
};


const mapStateToProps = state => {
	return {
		Auth: state.Auth,
		blockRouting: state.App.blockRouting,
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(Router);