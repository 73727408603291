export const CHARTING_OPTIONS_SET = 'CHARTING_OPTIONS_SET';
export const CHARTING_TOOL_STATE_SET = 'CHARTING_TOOL_STATE_SET';
export const CHARTING_OPTIONS_RESET = 'CHARTING_OPTIONS_RESET';


export function setChartingOptions(options) {
	return {
		type: CHARTING_OPTIONS_SET,
		options
	};
}


export function setToolState(toolName, enabled) {
	return {
		type: CHARTING_TOOL_STATE_SET,
		toolName,
		enabled
	};
}


export function resetChartingOptions() {
	return {
		type: CHARTING_OPTIONS_RESET,
	};
}
