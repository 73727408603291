import isArray from 'lodash/isArray';
import { ERROR_SET, ERROR_CLOSE } from '../../actions/Global/ErrorDialog';



const defaultState = {
	errors: [],
	open: false,
};

export default (state = defaultState, action) => {
	switch ( action.type ) {
		case ERROR_SET:
			return setErrors(state, action);
		case ERROR_CLOSE:
			return {
				errors: [],
				open: false,
			};
		default:
			return state;
	}
}


function setErrors(state, action) {
	if ( !action.errors ) {
		return {
			errors: [],
			open: false,
		};
	}

	if ( !isArray(action.errors) ) {
		return {
			errors: [ action.errors ],
			open: true,
		};
	}

	return {
		errors: action.errors,
		open: true,
	};
}