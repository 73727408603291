import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
	Grid,
	Typography,
	CircularProgress,
} from '@material-ui/core';


// Don't show anything if it hasn't been at least this long
const minTime = 500;


const styles = theme => ({
	root: {
		flexGrow: 0,
		marginTop: 24,
		paddingLeft: 24,
		paddingRight: 24,
		height: '100%',
	},
	loadingContainer: {
		width: '100%',
		margin: 0,
		textAlign: 'center',
	},
});

class LoadingCover extends Component {

	state = {
		timerExpired: false,
	};


	componentDidMount = () => {
		setTimeout(() => {
			this.setState({timerExpired: true});
		}, minTime);
	};


	cover = () => {
		const classes = this.props.classes;

		return (
			<Grid container className={classes.root} spacing={0}>
				<Grid item xs={1} sm={1} md={3} lg={3} xl={3} />

				<Grid item xs={10} sm={10} md={6} lg={6} xl={6}>
					<div className={classes.loadingContainer}>
						<Typography variant={"h4"} gutterBottom>
							Loading...
						</Typography>
						<Typography variant={"h4"} gutterBottom>
							<CircularProgress size={100} />
						</Typography>
					</div>
				</Grid>

				<Grid item xs={1} sm={1} md={3} lg={3} xl={3} />
			</Grid>
		);
	};


	render() {

		if(this.props.waitToShow && !this.props.ready && !this.state.timerExpired) {
			return null;
		}

		return this.props.ready
			? this.props.children ? this.props.children : null
			: this.cover()
	}
}

LoadingCover.defaultProps = {
	ready: true,
	waitToShow: true,
};

LoadingCover.propTypes = {
	ready: PropTypes.bool.isRequired,
	waitToShow: PropTypes.bool.isRequired,
};


export default withStyles(styles)(LoadingCover);