import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearToken } from '../actions/Global/Auth';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {
	Paper,
	Grid,
	Typography,
} from '@material-ui/core';


const styles = theme => ({
	paper: {
		padding: 24,
	},
	typography: {
		marginTop: 24,
	},
});



class Logout extends Component {

	componentDidMount = () => {
		localStorage.removeItem('authToken');
		this.props.clearToken();
	};

	render() {
		const classes = this.props.classes;

		return (
			<Grid container spacing={24}>
				<Grid item xs={1} sm={1} md={1} lg={2} xl={2} />
				<Grid item xs={10} sm={10} md={10} lg={8} xl={8}>
					<Typography variant={"h4"} gutterBottom className={classes.typography}>
						Logged Out
					</Typography>
					<Paper className={classes.paper}>
						You have been logged out.  Please click <Link to="/">here</Link> to log in again.
					</Paper>
				</Grid>
				<Grid item xs={1} sm={1} md={1} lg={2} xl={2} />
			</Grid>
		)
	}

}



const mapStateToProps = state => {
	return {
		Auth: state.Auth,
	};
};


const mapDispatchToProps = dispatch => {
	return {
		clearToken: () => {
			dispatch(clearToken());
		},
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Logout));