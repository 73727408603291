export const API_BASE = process.env.REACT_APP_API_URL;

//export const API_BASE = 'http://home.mrdev.org:2266/api-beta/pub';

// Auth
export const API_AUTH           = `${API_BASE}/auth/login`;
export const API_CHECK_TOKEN    = `${API_BASE}/auth/check_token`;
export const API_FORGOT_PASSWORD = `${API_BASE}/auth/start_password_reset`;
export const API_CHECK_RESET_TOKEN = `${API_BASE}/auth/check_reset_token`;
export const API_RESET_PASSWORD = `${API_BASE}/auth/reset_password`;

// Client management
export const API_CREATE_CLIENT                  = `${API_BASE}/clients/create`;
export const API_VIEW_CLIENT                    = `${API_BASE}/clients/view`;
export const API_LIST_CLIENT                    = `${API_BASE}/clients/list`;
export const API_LIST_PATIENTS_BY_CLIENT        = `${API_BASE}/clients/get_patients`;
export const API_LIST_CONTACT_METHODS_BY_CLIENT = `${API_BASE}/clients/get_contact_methods`;
export const API_LIST_ADDRESSES_BY_CLIENT       = `${API_BASE}/clients/get_addresses`;
export const API_SAVE_CONTACT_METHOD            = `${API_BASE}/clients/save_contact_method`;
export const API_SAVE_ADDRESS                   = `${API_BASE}/clients/save_address`;
export const API_SET_DEFAULT_ADDRESS            = `${API_BASE}/clients/set_default_address`;
export const API_SET_DEFAULT_CONTACT_METHOD     = `${API_BASE}/clients/set_default_contact_method`;

// Patient management
export const API_LIST_PATIENT = `${API_BASE}/patients/list`;
export const API_SAVE_PATIENT = `${API_BASE}/patients/save`;
export const API_VIEW_PATIENT = `${API_BASE}/patients/view`;
export const API_LIST_CHARTS  = `${API_BASE}/patients/get_charts`;

// Charting
export const API_CHART_GET_CLIENT_DATA = `${API_BASE}/charts/get_client_data`;
export const API_CHART_SAVE            = `${API_BASE}/charts/save`;
export const API_CHART_LOAD            = `${API_BASE}/charts/load`;
export const API_CHART_SEARCH          = `${API_BASE}/charts/search`;

// Clinic Management
export const API_CLINIC_LOAD              = `${API_BASE}/clinic/load`;
export const API_CLINIC_SAVE              = `${API_BASE}/clinic/save`;
export const API_CLINIC_GET_USERS         = `${API_BASE}/clinic/get_users`;
export const API_CLINIC_ADD_USER          = `${API_BASE}/clinic/add_user`;
export const API_CLINIC_ADD_USER_BY_EMAIL = `${API_BASE}/clinic/add_user_by_email`;

// Reports
export const API_REPORTS_LIST              = `${API_BASE}/reports/list_reports`;
export const API_REPORTS_GET_LAYOUT        = `${API_BASE}/reports/get_layout`;
export const API_REPORTS_SAVE_TEMPLATE     = `${API_BASE}/reports/save_template`;
export const API_REPORTS_GET_TEMPLATE      = `${API_BASE}/reports/get_template`;
export const API_REPORTS_LIST_TEMPLATES    = `${API_BASE}/reports/list_templates`;
export const API_REPORTS_SAVE_REPORT       = `${API_BASE}/reports/save_report`;
export const API_REPORTS_GET_REPORT        = `${API_BASE}/reports/get_report`;
export const API_REPORTS_LIST_USER_REPORTS = `${API_BASE}/reports/list_user_reports`;
export const API_REPORTS_GET_USER_STRINGS  = `${API_BASE}/reports/get_user_strings`;
export const API_REPORTS_SAVE_USER_STRING  = `${API_BASE}/reports/save_user_string`;
export const API_REPORTS_GET_PDF           = `${API_BASE}/reports/get_pdf`;

// Images
export const API_IMAGES_UPLOAD         = `${API_BASE}/images/upload`;
export const API_IMAGES_GET_QUOTA      = `${API_BASE}/images/get_quota`;
export const API_IMAGES_GET_RECENT     = `${API_BASE}/images/list_recent`;
export const API_IMAGES_SEARCH_UPLOADS = `${API_BASE}/images/search_uploads`;

// Version
export const API_VERSION_CHECK     = `${API_BASE}/version/check`;
export const API_VERSION_CHANGELOG = `${API_BASE}/version/changelog`;