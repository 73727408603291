import upload_file from './requests/upload_file';
import get_quota from './requests/get_quota';
import get_recent_uploads from './requests/get_recent_uploads';
import search_uploads from './requests/search_uploads';



export const types = {
	SET_STATE: 'UPLOADER/SET_STATE',
	RESET: 'UPLOADER/RESET',
	CHANGE_PAGE: 'UPLOADER/CHANGE_PAGE',
	SET_UPLOAD_OPTIONS: 'UPLOADER/SET_UPLOAD_OPTIONS',
	SET_CARD: 'UPLOADER/SET_CARD',
	SET_BROWSE_OPTIONS: 'UPLOADER/SET_BROWSE_OPTIONS',
};

const d = new Date();

const initialState = {
	open: false,
	page: 'upload', // upload, browse, recent
	quotaUsed: null,
	browseOptions: {
		client_name: '',
		patient_name: '',
		visit_id: '',
		urpt_name: '',
		file_name: '',
		year: d.getFullYear(),
		month: '',
		loading: false,
		files: [],
		searched: false,
		error: false,
		limit: 1,
		totalRows: 0,
		overLimit: false,
		page: 1,
		filesPerPage: 20,
	},
	uploadOptions: {
		data: null,
		name: '',
		extension: '',
		mime: '',
		size: 0,
		error: false,
		success: false,
		uploading: false,
		errorText: 'Unknown Error',
		selectedUrl: null,
		selectedThumbUrl: null,
	},
	uploadKeys: {
		client_id: null,
		patient_id: null,
		visit_id: null,
		urpt_id: null,
	},
};


export default (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };
		case types.RESET:
			return { ...initialState };
		case types.CHANGE_PAGE:
			return changePage(state, action);
		case types.SET_UPLOAD_OPTIONS:
			return { ...state, uploadOptions: { ...state.uploadOptions, ...action.data } };
		case types.SET_BROWSE_OPTIONS:
			return { ...state, browseOptions: { ...state.browseOptions, ...action.data } };
		default:
			return state;
	}
}

const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),
	setUploadOptions: (data) => ({ type: types.SET_UPLOAD_OPTIONS, data }),
	setBrowseOptions: (data) => ({ type: types.SET_BROWSE_OPTIONS, data }),
	openUploader: () => ({ type: types.SET_STATE, data: { open: true } }),
	cancelUploader: () => ({ type: types.RESET }),
	resetUpload: () => ({ type: types.SET_STATE, data: { uploadOptions: { ...initialState.uploadOptions } } }),
	changePage: (page, options = {}) => ({ type: types.CHANGE_PAGE, page, options }),

	uploadFile: () => (dispatch, getState) => {
		let state = getState().uploader;
		dispatch({ type: types.SET_UPLOAD_OPTIONS, data: { uploading: true } });

		const uploadOptions = {
			name: state.uploadOptions.name,
			extension: state.uploadOptions.extension,
			mime: state.uploadOptions.mime,
			size: state.uploadOptions.size,
		};
		const uploadKeys    = { ...state.uploadKeys };

		upload_file(state.uploadOptions.data, uploadKeys, uploadOptions).then(res => {
			dispatch({
				type: types.SET_UPLOAD_OPTIONS,
				data: { uploading: false, success: true, selectedUrl: res.url, selectedThumbUrl: res.thumbnail },
			});

			dispatch(actions.getQuota());
		}).catch(err => {
			dispatch({ type: types.SET_UPLOAD_OPTIONS, data: { uploading: false } });
		});
	},

	getQuota: () => (dispatch) => {
		get_quota().then(res => {
			dispatch({ type: types.SET_STATE, data: { quotaUsed: res } });
		}).catch(err => {
			dispatch({ type: types.SET_STATE, data: { quotaUsed: 'Unknown' } });
		});
	},

	loadRecent: () => (dispatch, getState) => {
		let state = getState().uploader.browseOptions;
		dispatch({
			type: types.SET_STATE,
			data: { browseOptions: { ...initialState.browseOptions, filesPerPage: state.filesPerPage, loading: true } },
		});

		get_recent_uploads().then(res => {
			dispatch({ type: types.SET_BROWSE_OPTIONS, data: { loading: false, files: res } });
		}).catch(err => {
			dispatch({ type: types.SET_STATE, data: { browseOptions: { ...initialState.browseOptions } } });
		});
	},

	search: (data = {}) => (dispatch, getState) => {
		let state      = getState().uploader.browseOptions;
		let searchVals = {
			client_name: state.client_name,
			patient_name: state.patient_name,
			visit_id: state.visit_id,
			urpt_name: state.urpt_name,
			year: state.year,
			month: state.month,
			file_name: state.file_name,
			...data,
		};

		dispatch({
			type: types.SET_BROWSE_OPTIONS,
			data: { loading: true, files: [], searched: false, error: false, ...data },
		});

		search_uploads(searchVals).then(res => {
			dispatch({
				type: types.SET_BROWSE_OPTIONS,
				data: {
					loading: false,
					files: res.files,
					searched: true,
					error: false,
					limit: res.limit,
					overLimit: res.over_limit,
					totalRows: res.total_rows,
				},
			});
		}).catch(err => {
			dispatch({
				type: types.SET_BROWSE_OPTIONS,
				data: { ...state, loading: false, files: [], searched: true, error: true },
			});
		});
	},
};



function changePage(state, action) {
	if(state.page === action.page) return state;
	return {
		...state,
		page: action.page,
		browseOptions: { ...initialState.browseOptions, ...action.options },
		uploadOptions: { ...initialState.uploadOptions },
	};
}

export { actions, initialState };