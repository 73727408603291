export const ERROR_SET = 'ERROR_SET';
export const ERROR_CLOSE = 'ERROR_CLOSE';


export let setErrors = (errors) => {
	return {
		type: ERROR_SET,
		errors,
	};
};


export let closeErrorDialog = () => {
	return {
		type: ERROR_CLOSE,
	};
};