export const SET_CONTENT_HEIGHT = 'SET_CONTENT_HEIGHT';


export let setContentHeight = (height) => {
	return {
		type: SET_CONTENT_HEIGHT,
		height
	};
};

