import { SET_CONTENT_HEIGHT } from '../../actions/Global/ContentHeight';

const defaultState = 0;



export default function ( state = defaultState, action ) {
	switch (action.type) {
		case SET_CONTENT_HEIGHT:
			return setHeight(action);
		default:
			return state;
	}
}


function setHeight(action) {
	return action.height;
}
