import React from 'react';
import { CircularProgress, Button } from '@material-ui/core';
import PropTypes from 'prop-types';



function SpinnerButton(props) {
	const {isSpinning, defaultText, children, onClick, ...buttonProps} = props;

	return (
		<Button {...buttonProps}
		onClick={props.isSpinning ? null : props.onClick || null}>
			{props.isSpinning
			? <CircularProgress color="secondary" size={20} />
			: props.defaultText}
		</Button>
	);
}

SpinnerButton.defaultProps = {
	isSpinning: false,
};

SpinnerButton.propTypes = {
	isSpinning: PropTypes.bool.isRequired,
	defaultText: PropTypes.string.isRequired
};

export default SpinnerButton;