import {
	AUTH_SET,
	AUTH_SET_PENDING,
	AUTH_SET_FULFILLED,
	AUTH_SET_REJECTED,
	AUTH_CHECK_TOKEN,
	AUTH_CHECK_TOKEN_PENDING,
	AUTH_CHECK_TOKEN_FULFILLED,
	AUTH_CHECK_TOKEN_REJECTED,
	AUTH_CLEAR_TOKEN,
} from '../../actions/Global/Auth';

const defaultState = {
	valid: false,
	pending: false,
	token: null,
	needsReauth: false,
	clinic: null,
	availableClinics: [],
};

export default ( state = defaultState, action ) => {
	switch (action.type) {
		case AUTH_CHECK_TOKEN:
		case AUTH_SET:
			return initAuth(state, action);
		case AUTH_CHECK_TOKEN_PENDING:
		case AUTH_SET_PENDING:
			return pendingAuth(state, action);
		case AUTH_CHECK_TOKEN_FULFILLED:
		case AUTH_SET_FULFILLED:
			return fulfilledAuth(state, action);
		case AUTH_CHECK_TOKEN_REJECTED:
		case AUTH_SET_REJECTED:
			return rejectedAuth(state, action);
		case AUTH_CLEAR_TOKEN:
			return {...defaultState};
		default:
			return state;
	}
}


function initAuth(state, action) {
	//console.log('initAuth', action);
	return {...defaultState};
}



function pendingAuth(state, action) {
	//console.log('pendingAuth', action);
	return {...state, pending: true};
}


function fulfilledAuth(state, action) {
	//console.log('fulfilledAuth', action);
	return {
		...state,
		valid: true,
		pending: false,
		token: action.payload.data.data.token,
		clinic: 0,
		availableClinics: action.payload.data.data.availableClinics,
	};
}


function rejectedAuth(state, action) {
	//console.log('rejectedAuth', action.payload.response.data.msg);
	return {...defaultState, token: null};
}