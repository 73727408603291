// Required
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import SpinnerButton from '../components/form/SpinnerButton';
import { checkToken, setAuth } from '../actions/Global/Auth';
import { setApp } from '../actions/Global/App';
import { Avatar, Card, CardActions, CardContent, CardHeader, LinearProgress, TextField } from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';
import {Link} from 'react-router-dom';



const styles = theme => ({
	card: {
		maxWidth: 400,
		marginTop: 24,
		margin: 'auto',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	textField: {
		width: '100%',
	},
	button: {
		width: '100%',
		height: 32,
	},
	progress: {
		width: '100%',
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main,
	},
	linkColor: {
		color: theme.palette.primary[700],
		'&:visited': {
			color: theme.palette.primary[700],
		},
		'&:link': {
			color: theme.palette.primary[700],
		},
		'&:hover': {
			color: theme.palette.primary[400],
		},
	},
});



class LoginPage extends Component {

	componentDidMount = () => {
		let auth = this.props.Auth;
		if(auth.token && !auth.valid && !auth.pending) {
			this.props.checkToken(auth.token);
		}
	};


	sendAuthRequest = () => {
		if(!this.validateCredentials()) {
			return false;
		}

		return this.props.setAuth({
			u: this.state.username,
			p: this.state.password,
		});
	};


	state = {
		username: '',
		password: '',
		userError: false,
		passError: false,
	};


	updateUsername = (e) => {
		this.setState({ username: e.target.value });
	};


	updatePassword = (e) => {
		this.setState({ password: e.target.value });
	};


	handleKeyPress = (e) => {
		if(e.key === 'Enter') {
			return this.sendAuthRequest();
		}
	};


	validateCredentials = () => {
		let user = this.state.username,
			pass = this.state.password;
		let errors = {
			userError: false,
			passError: false,
		};

		if(user.trim().length < 3) {
			errors.userError = true;
		}

		if(pass.trim().length < 10) {
			errors.passError = true;
		}

		this.setState(errors);

		return !errors.userError && !errors.passError;
	};


	render() {
		const classes = this.props.classes;

		if(this.props.Auth.token && !this.props.Auth.valid) {
			return (
				<div>
					<LinearProgress
						className={classes.progress}
						color={'secondary'}
						variant={'query'}
					/>
				</div>
			);
		}

		return (
			<div>
				<Card className={classes.card}>
					<Avatar className={classes.avatar}>
						<LockOutlined />
					</Avatar>

					<CardHeader
						title="Sabertooth Beta Login"
						subheader="Enter your registered email address and password"
					/>

					<CardContent>
						<TextField
							label="Email Address"
							className={classes.textField}
							type="text"
							margin="normal"
							value={this.state.username}
							onChange={this.updateUsername}
							error={this.state.userError}
							onKeyPress={this.handleKeyPress}
							inputProps={{
								autoCorrect: 'off',
								autoCapitalize: 'off',
							}}
						/>
						<TextField
							label="Password"
							className={classes.textField}
							type="password"
							margin="normal"
							value={this.state.password}
							onChange={this.updatePassword}
							error={this.state.passError}
							onKeyPress={this.handleKeyPress}
						/>
					</CardContent>

					<CardActions>
						<SpinnerButton
							variant="contained"
							color="primary"
							defaultText="Log In"
							className={classes.button}
							isSpinning={this.props.Auth.pending}
							onClick={this.sendAuthRequest}
						/>
					</CardActions>
				</Card>
				<div className={classes.card}>
					<Link className={classes.linkColor} to="/forgotpassword">Forgot password?</Link>
				</div>
			</div>
		)
	}
}



const mapStateToProps = state => {
	return {
		Auth: state.Auth,
		App: state.App,
	};
};


const mapDispatchToProps = dispatch => {
	return {
		setAuth: options => {
			dispatch(setAuth(options));
		},
		setApp: data => {
			dispatch(setApp(data));
		},
		checkToken: token => {
			dispatch(checkToken(token));
		},
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginPage));