import axios from 'axios';
import { API_IMAGES_UPLOAD as REQUEST_URL } from "config/index";
import reduxStore from 'config/reduxStore';


function request(file, keys = {}, options = {}) {
	let auth     = reduxStore.getState().Auth;
	let clinicID = auth.availableClinics[ auth.clinic ].id;

	let requestConfig = {
		method: 'post',
		url: `${REQUEST_URL}`,
		withCredentials: true,
		headers: { Authorization: `Bearer ${auth.token}` },
		data: {
			clinic: clinicID,
			file: file,
			keys: keys,
			options: options,
		},
	};

	return axios.request(requestConfig)
		.then((res) => {
			if(!res.data.data || !res.data.data.url) {
				throw res;
			}

			return res.data.data;
		}).catch((err) => {
			throw err;
		});
}

export default request;