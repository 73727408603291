import {
	CHARTING_OPTIONS_SET,
	CHARTING_TOOL_STATE_SET,
	CHARTING_OPTIONS_RESET,
} from '../../actions/Charting/ChartingOptions';
import { colorPicker } from '../../config/Colors';


const defaultState = {
	UndoDispatch: false, // Bool
	UndoAction: {}, // Must contain canvas attribute
	PathologiesEnabled: false,
	ActiveTool: null,
	CustomTool: null,
	ChartingType: 'perio',
	Markup: {
		Tool: 'draw',
		Color: colorPicker[colorPicker.length-1],
		Size: 8,
	},
	ActiveTooth: 't101',
	ActiveDiagnosis: null,
	ActiveTreatment: null,
	ChartingTools: {
		perio: true,
		plaqueIndex: false,
		calculusIndex: false,
		gingivalIndex: false,
	},
	Hydrated: {
		buccal: false,
		occlusal: false,
		charting: false,
	},
	SaveBuccal: null,
	SaveOcclusal: null,
	showInfoDialog: true,
	showCopyDialog: false,
};



export default function ( state = defaultState, action ) {
	switch (action.type) {
		case CHARTING_OPTIONS_SET:
			return setOptions(state, action);
		case CHARTING_TOOL_STATE_SET:
			return setToolState(state, action);
		case CHARTING_OPTIONS_RESET:
			return reset();
		default:
			return state;
	}
}


function setOptions(state, action) {
	return {...state, ...action.options};
}


function setToolState(state, action) {
	return {...state, ChartingTools: {...state.ChartingTools, [action.toolName]: action.enabled}};
}


function reset() {
	return {...defaultState};
}