import React from 'react';
import buildNumber from 'buildNumber';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { RadioButtonUnchecked, SystemUpdate } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import version_check from 'requests/version_check';



class UpdateNotifier extends React.PureComponent {

	state = { ...buildNumber, showDialog: false };
	updateInterval = null;

	checkForUpdate = () => {
		version_check().then(res => {
			if(res.build !== buildNumber.build) {
				this.setState(res);
				//clearInterval(this.updateInterval);
				//this.updateInterval = null;
			}
		}).catch(err => {
			console.error(err)
		});
	};


	componentDidMount = () => {
		// Check for update immediately, then check every 10 minutes.
		this.checkForUpdate();
		this.updateInterval = setInterval(this.checkForUpdate, 600000);
	};


	openDialog = e => {
		this.setState({ showDialog: true });
	};


	closeDialog = e => {
		this.setState({ showDialog: false });
	};


	reloadPage = e => {
		// eslint-disable-next-line
		location.reload(true);
	};


	render() {
		const { classes } = this.props;
		const { build, showDialog, version } = this.state;

		if(buildNumber.build === build) {
			return (
				<Tooltip title="No updates available - you're on the current version!">
					<IconButton className={classes.updateButton}>
						<RadioButtonUnchecked className={classes.updateIconInactive} />
					</IconButton>
				</Tooltip>
			);
		}

		return (
			<div>
				<Tooltip title="Update available! Click for information.">
					<IconButton className={classes.updateButton} onClick={this.openDialog}>
						<SystemUpdate className={classes.updateIcon} />
					</IconButton>
				</Tooltip>

				<Dialog
					open={showDialog}
				>
					<DialogTitle>Update Available</DialogTitle>

					<DialogContent>
						<Typography paragraph>
							A new update for Sabertooth Charting is available! Updating is simple: click the reload
							button in your browser, or click the reload button at the bottom of this window. If you are
							in the middle of unsaved work, please click the close button below, save your work, and then
							reload.
						</Typography>

						<Typography>
							Your Version: {buildNumber.version} build {buildNumber.build}
						</Typography>
						<Typography>
							New Version: <strong>{version} build {build}</strong>
						</Typography>
					</DialogContent>

					<DialogActions>
						<Button onClick={this.reloadPage}>Reload</Button>
						<Button onClick={this.closeDialog}>Close</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}



const styles = theme => ({
	updateIcon: {
		color: '#ffffff',
		fontSize: '1.4em',
	},
	updateIconInactive: {
		color: '#ffffff',
		fontSize: '1.4em',
		opacity: 0.25,
	},
	updateButton: {
		marginRight: theme.spacing.unit * 2,
	},
});

export default withStyles(styles)(UpdateNotifier);