import { SNACKBAR_SET } from '../../actions/Global/SnackBar';

const defaultState = {
	text: '',
	timeout: 5000,
	open: false
};



export default function ( state = defaultState, action ) {
	switch (action.type) {
		case SNACKBAR_SET:
			return setSnackbar(action);
		default:
			return state;
	}
}


function setSnackbar(action) {
	return {
		text: action.text,
		open: !!action.open,
		timeout: action.timeout,
	}
}
