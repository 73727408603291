import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';

const styles = theme => ({
	root: {
		width: '100%',
		padding: theme.spacing.unit * 2,
		background: theme.palette.primary[500],
		color: '#ffffff',
	},
});

class TopCover extends PureComponent {
	render() {
		const {children, className, classes, ...restProps} = this.props;

		return (
			<div className={classnames(classes.root, className && className)} {...restProps}>
				{children}
			</div>
		);
	}
}

export default withStyles(styles)(TopCover);