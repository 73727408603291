import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import actions from 'actions';



class ErrorDialog extends React.PureComponent {

	render() {
		const { messages, open, close } = this.props;

		return (
			<Dialog
				open={(open && !!messages.length)}
				onClose={close}
				disableBackdropClick
				disableEscapeKeyDown
			>
				<DialogTitle>
					{messages.length === 1 && 'Oops, we ran into a problem!'}
					{messages.length > 1 && 'Oops, we ran into some problems!'}
				</DialogTitle>

				<DialogContent>
					{messages.length === 1 &&
					<div>
						<div>
							{messages[ 0 ]}
						</div>
					</div>
					}

					{messages.length > 1 &&
					<div>
						<div>
							<ul>
								{messages.map((msg, index) => {
									return <li key={index}>{msg}</li>;
								})}
							</ul>
						</div>
					</div>
					}
				</DialogContent>

				<DialogActions>
					<Button onClick={close}>Close</Button>
				</DialogActions>
			</Dialog>
		);
	}
}



ErrorDialog.defaultProps = {
	open: false,
};

ErrorDialog.propTypes = {
	messages: PropTypes.array.isRequired,
	open: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
	setErrors: PropTypes.func.isRequired,
};


const mapStateToProps = state => {
	return {
		open: state.ErrorDialog.open,
		messages: state.ErrorDialog.errors,
	}
};


const mapDispatchToProps = dispatch => ({
	close: () => {
		dispatch(actions.closeErrorDialog());
	},
	setErrors: (errors) => {
		dispatch(actions.setErrors(errors));
	},
});


export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialog);