import { setTitleBarVars } from './Global/TitleBar';
import { clearToken, setAuth } from './Global/Auth';
import { setApp } from './Global/App';
import { resetChartingOptions, setChartingOptions, setToolState } from './Charting/ChartingOptions';
import {
	hydrateChart,
	redo,
	resetChart,
	saveDiagnostics,
	saveDrawing,
	saveSymbols,
	saveText,
	saveShape,
	saveTreatments,
	setActiveChartOption,
	setChartingValue,
	setLayerVisibility,
	undo,
} from './Charting/ActiveChart';
import { setSnackbar } from "./Global/SnackBar";
import { setContentHeight } from './Global/ContentHeight';
import { closeErrorDialog, setErrors } from './Global/ErrorDialog';
import {actions as uploaderActions} from 'containers/ImageUploader/ImageUploader.redux';



export default {
	setTitleBarVars,
	setAuth,
	setChartingOptions,
	setToolState,
	setChartingValue,
	setSnackbar,
	setActiveChartOption,
	saveDrawing,
	saveText,
	saveShape,
	saveSymbols,
	hydrateChart,
	resetChart,
	resetChartingOptions,
	setContentHeight,
	setLayerVisibility,
	saveDiagnostics,
	saveTreatments,
	clearToken,
	undo,
	redo,
	setApp,
	setErrors,
	closeErrorDialog,
	uploader: uploaderActions,
};