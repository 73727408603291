import _ from 'lodash';
import { TITLEBAR_SET_VARS } from '../../actions/Global/TitleBar';

const defaultState = {
	color: null,
	leftMenuAction: null,
	rightMenuAction: null,
	text: 'Sabertooth Charting'
};



export default function ( state = defaultState, action ) {
	let newState = _.clone(state);

	switch (action.type) {
		case TITLEBAR_SET_VARS:
			return setVars(newState, action);
		default:
			return state;
	}
}


function setVars(state, action) {
	// Only merges keys that already exist in state
	return _.assign(state, _.pick(action.values, _.keys(state)));
}
