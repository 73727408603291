import React from 'react';
import { Route } from 'react-router-dom';
import LoginPage from '../containers/LoginPage';

function PrivateRoute ({auth, render: remoteRender, ...rest}) {
	//console.log('auth', rest );
	return (
		<Route
			{...rest}
			render={(props) => auth.valid || (!auth.valid && auth.needsReauth)
				? remoteRender(props)
				: <LoginPage />}
		/>
	)
}

export default PrivateRoute;