import axios from 'axios';
import { API_IMAGES_GET_QUOTA as REQUEST_URL } from "config/index";
import reduxStore from 'config/reduxStore';


function request() {
	let auth     = reduxStore.getState().Auth;
	let clinicID = auth.availableClinics[ auth.clinic ].id;

	let requestConfig = {
		method: 'post',
		url: `${REQUEST_URL}`,
		withCredentials: true,
		headers: { Authorization: `Bearer ${auth.token}` },
		data: {
			clinic: clinicID,
		},
	};

	return axios.request(requestConfig)
		.then((res) => {
			if(!res.data.data || !res.data.data.quota) {
				throw res;
			}

			return res.data.data.quota;
		}).catch((err) => {
			throw err;
		});
}

export default request;