import {APP_SET} from '../../actions/Global/App';

const defaultState = {
	dataHash: null,
	blockRouting: false,
	forceScrollY: false,
};

export default ( state = defaultState, action ) => {
	switch (action.type) {
		case APP_SET:
			return {...state, ...action.data};
		default:
			return state;
	}
}