import {
	amber,
	blue,
	blueGrey,
	brown,
	cyan,
	deepOrange,
	deepPurple,
	green,
	grey,
	indigo,
	lightBlue,
	lightGreen,
	lime,
	orange,
	pink,
	purple,
	red,
	teal,
	yellow,
} from '@material-ui/core/colors/';



export const defaultStroke = grey[ 600 ];
export const defaultFill = grey[ 100 ];
const defaultStrokeLight = grey[ 200 ];
const defaultFillLight = '#fafafa';
export const defaultStrokeDeciduous = lightBlue[ 600 ];
export const defaultFillDeciduous = lightBlue[ 100 ];
const defaultStrokeLightDeciduous = lightBlue[ 200 ];
const defaultFillLightDeciduous = '#edf9ff';
export const selectStroke = '#64DD17';
export const selectFill = '#CCFF90';
export const defaultPocket = '#C5CAE9';
export const selectPocket = '#FFE0B2';
export const pocketText = '#000000';
export const pocketLabel = grey[ 500 ];
export const colorPicker = [
	red[ 700 ], pink[ 700 ], purple[ 700 ], deepPurple[ 700 ],
	indigo[ 700 ], blue[ 700 ], lightBlue[ 700 ], cyan[ 700 ],
	teal[ 700 ], green[ 700 ], lightGreen[ 700 ], lime[ 700 ],
	yellow[ 700 ], amber[ 700 ], orange[ 700 ], deepOrange[ 700 ],
	brown[ 700 ], grey[ 700 ], blueGrey[ 700 ], '#000000',
];

export default {
	defaultStroke,
	defaultFill,
	defaultStrokeLight,
	defaultFillLight,
	defaultStrokeLightDeciduous,
	defaultFillLightDeciduous,
	selectStroke,
	selectFill,
	defaultPocket,
	selectPocket,
	pocketText,
	defaultFillDeciduous,
	defaultStrokeDeciduous,
	pocketLabel,
}