import promiseMiddleware from "redux-promise-middleware";
import thunkMiddleware from "redux-thunk";
import reducers from "../reducers";
import {applyMiddleware, createStore} from "redux";

// Load the auth token into default auth state if we have it
const authToken = {
	Auth: {
		valid      : false,
		pending    : false,
		token      : localStorage.getItem('authToken'),
		needsReauth: false,
	}
};



let store;

if(process.env.REACT_APP_STAGE === 'dev') {
	store = applyMiddleware(
		thunkMiddleware,
		promiseMiddleware
	)(createStore)(reducers, authToken, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
} else {
	store = applyMiddleware(
		thunkMiddleware,
		promiseMiddleware
	)(createStore)(reducers, authToken);
}



export default store;