import React, { PureComponent } from 'react';
import { Typography } from "@material-ui/core";
import TopCover from "components/TopCover";
import { withStyles } from '@material-ui/core/styles';
import { QuestionAnswer } from "@material-ui/icons";



class Faq extends PureComponent {
	render() {
		const { classes } = this.props;


		return (
			<React.Fragment>

				<TopCover>
					<QuestionAnswer className={classes.bigIcon} />
					<div>
						<Typography
							variant="h4"
							gutterBottom
							color="inherit"
						>
							Beta FAQ
						</Typography>
					</div>
				</TopCover>

				<main className={classes.layout}>
					<div className={classes.heroContent}>
						<Typography variant={'caption'} paragraph>
							This document was last updated on February 27, 2019.
						</Typography>


						<Typography variant="h6">
							<strong>I found a bug or have a suggestion! Who do I tell?</strong>
						</Typography>

						<Typography paragraph>
							We greatly appreciate any suggestions or bug reports you can provide, and you may do so by
							simply sending an email to support@sabertoothcharting.com. We can't promise we'll implement
							every suggestion, but we'll certainly take all of them into consideration. If submitting a
							bug report, please be as detailed as possible - let us know what you were trying to do,
							any error messages you received, and if you were able to resolve the issue in any way. The
							more detailed your bug reports are, the faster we can determine how to reproduce the issue
							and get it fixed.
						</Typography>


						<Typography variant="h6">
							<strong>Is there any training material available for Sabertooth Charting?</strong>
						</Typography>

						<Typography paragraph>
							We're working on a few videos which we plan to post on our site soon. These will cover most
							functionality of the application, but we plan to add more as time goes on. Stay tuned!
						</Typography>


						<Typography variant="h6">
							<strong>
								Can I change the order that Sabertooth Charting automatically advances through teeth
								when performing perio probing?
							</strong>
						</Typography>

						<Typography paragraph>
							Not yet, but we received a lot of requests for this and it is a high priority item. We're
							going to implement this as a per-clinic setting.
						</Typography>


						<Typography variant="h6">
							<strong>
								Can I set what values are considered "abnormal" for pocket depths, gingival
								recession/hyperplasia, etc?
							</strong>
						</Typography>

						<Typography paragraph>
							Yes! We've added this feature based on popular feedback. When starting a new chart, you
							will find a field to enter Abnormal Pocket Depth on the Visit Information dialog. If you
							need to set the value after Visit Information has been closed or on an existing chart,
							click the <strong>Extras</strong> button, then click <strong>Initial Info</strong>.
						</Typography>


						<Typography variant="h6">
							<strong>
								Since you're cloud-based, does that mean I need an internet connection? What happens if
								I lose connection?
							</strong>
						</Typography>

						<Typography>
							Short answer: Yes, you need to have an active internet connection.
						</Typography>

						<Typography paragraph>
							Longer answer: No network requests are made while the charting view is loaded (except
							Autosave), so you can continue charting if you're already on the charting page. In this
							case, you can save your chart once connectivity is restored. We do plan to implement offline
							support, but it is a complex issue to solve and will likely not be available until several
							months after launch. With any cloud-based service, even outside the veterinary space, we
							always recommend having a pen-and-paper backup handy to get you through moments of
							intermittent connectivity, which you can then enter into your cloud-based solutions.
						</Typography>


						<Typography variant="h6">
							<strong>Can I add my own custom codes for diagnostics and treatments?</strong>
						</Typography>

						<Typography paragraph>
							Not yet, but this is coming very soon! We know that not everyone wants to use the AVDC codes
							for everything, and that the AVDC list doesn't cover everything. This will be added as a
							per-clinic setting.
						</Typography>


						<Typography variant="h6">
							<strong>
								Can I mark multiple diagnostics or treatments on a tooth without having to select a new
								code each time?
							</strong>
						</Typography>

						<Typography paragraph>
							Our workflow is currently such that you select a code, then place it on as many teeth as
							necessary, then move on to the next code. We realize this is inefficient for some users and
							received feedback indicating as such. We plan to implement a new mode for placing
							diagnostics and treatments which will allow you to click a tooth, then start typing
							abbreviations in a small box that pop up where you clicked. This will filter the
							abbreviations list to those matching (fully or partially) what has been entered. Clicking or
							tapping one of the auto-suggested abbreviations will add it to the tooth, and you can
							continue typing and clicking additional abbreviations as needed. When complete, there will
							be a confirmation button which will tie all selected items to the tooth, at which point you
							can move on to the next tooth. This new mode will be in addition to the current method, not
							a replacement of it.
						</Typography>


						<Typography variant="h6">
							<strong>Can I delete pets, charts, clients, patients, files, etc?</strong>
						</Typography>

						<Typography paragraph>
							Not yet, but we're adding the ability to remove items soon.
						</Typography>


						<Typography variant="h6">
							<strong>Can I upload multiple images at once?</strong>
						</Typography>

						<Typography paragraph>
							Not at this time. We do plan to add the ability to upload multiple images, but it's
							currently lower priority than many of our other tasks.
						</Typography>


						<Typography variant="h6">
							<strong>When does the beta end?</strong>
						</Typography>

						<Typography paragraph>
							We're aiming for the first half 2019, but ultimately we will not leave beta until we're
							satisfied with the state of the product. If we need to extend the beta, we will, and your
							account will remain free until we're ready to go live.
						</Typography>


						<Typography variant="h6">
							<strong>Will Sabertooth Charting be available in other languages?</strong>
						</Typography>

						<Typography paragraph>
							We'd love to do this, but it's a <em>huge</em> undertaking. Not only would we need to
							translate everything we've already done, but we'd have to get translations for any wording
							we add or change in the future, for every language we potentially support. We're not saying
							never, but it's not something we can justify quite yet.
						</Typography>


						<Typography variant="h6">
							<strong>
								Will Sabertooth Charting add support for animals other than canines and felines?
							</strong>
						</Typography>

						<Typography paragraph>
							Yes! This will not happen until after we go live, but we've had plenty of requests for
							additional animals. Some of these will require some backend data structure changes which
							will take us additional time, while others have additional requirements that will need to be
							met first. The equine industry, in particular, has a strong need for offline support (see
							earlier in this document for the status of offline support).
						</Typography>


					</div>
				</main>

			</React.Fragment>
		);
	}
}



const styles = theme => ({
	bigIcon: {
		float: 'left',
		fontSize: 80,
		marginTop: -25,
		marginRight: 40,
		marginLeft: 40,
	},
	heroContent: {
		maxWidth: 800,
		margin: '0 auto',
		padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
	},
	layout: {
		width: 'auto',
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[ theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2) ]: {
			width: 1100,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
});



export default withStyles(styles)(Faq);