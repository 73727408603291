import axios from 'axios';
import {API_VERSION_CHECK as REQUEST_URL} from "../config";

function request() {
	let options = {
		method: 'get',
		url: REQUEST_URL,
		withCredentials: true,
	};

	return axios.request(options)
		.then((res) => {
			//console.log('loaded layout', res.data);
			return res.data.data;
		}).catch((err) => {
			throw err;
		});
}

export default request;