import React from 'react';
import { Provider } from 'react-redux';
import store from '../config/reduxStore';
import { setContentHeight } from '../actions/Global/ContentHeight';
import Router from './Router';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { amber, red } from '@material-ui/core/colors';



const theme = createMuiTheme({
	palette: {
		primary: {
			light: '#7d96ff',
			main: '#003fae',
			dark: '#003fae',
			contrastText: '#fff',
			'900': '#001B84',
			'800': '#002994',
			'700': '#00319D',
			'600': '#0039A7',
			'500': '#003FAE',
			'400': '#265CBA',
			'300': '#4D79C6',
			'200': '#809FD7',
			'100': '#B3C5E7',
			'50': '#E0E8F5',
		},
		secondary: amber,
		error: red,
	},
	typography: {
		useNextVariants: true,
	},
});


// Set auth token in localstorage if it's not set or has changed
store.subscribe(() => {
	let token = store.getState().Auth.token;
	if(localStorage.authToken !== token) {
		if(token) {
			localStorage.setItem('authToken', token);
		} else {
			localStorage.removeItem('authToken');
			console.log('removed token')
		}

	}
});


// Oh my god this is so stupid
window.addEventListener("resize", setNewHeight);

function setNewHeight() {
	let content = document.querySelector('.content');
	if(!content) return false;
	setTimeout(() => {
		store.dispatch(setContentHeight(content.clientHeight));
	}, 150)

}


let lastTouchEnd = 0;
document.addEventListener('touchend', function(event) {
	let now = (new Date()).getTime();
	if(now - lastTouchEnd <= 300) {
		event.preventDefault();
	}
	lastTouchEnd = now;
}, false);



class App extends React.Component {

	render() {
		return (
			<Provider store={store}>
				<MuiThemeProvider theme={theme}>
					<Router />
				</MuiThemeProvider>
			</Provider>
		)
	}


}




export default App;