export const CHARTING_SET_VALUE = 'CHARTING_SET_VALUE';
export const CHARTING_SET_OPTION = 'CHARTING_SET_OPTION';
export const CHARTING_SAVE_DRAWING = 'CHARTING_SAVE_DRAWING';
export const CHARTING_SAVE_TEXT = 'CHARTING_SAVE_TEXT';
export const CHARTING_SAVE_SYMBOLS = 'CHARTING_SAVE_SYMBOLS';
export const CHARTING_HYDRATE = "CHARTING_HYDRATE";
export const CHARTING_RESET = "CHARTING_RESET";
export const CHARTING_SET_VISIBILITY = "CHARTING_SET_VISIBILITY";
export const CHARTING_SAVE_DIAGNOSTICS = "CHARTING_SAVE_DIAGNOSTICS";
export const CHARTING_SAVE_TREATMENTS = "CHARTING_SAVE_TREATMENTS";
export const CHARTING_SAVE_SHAPES = "CHARTING_SAVE_SHAPES";
export const CHARTING_UNDO = "CHARTING_UNDO";
export const CHARTING_REDO = "CHARTING_REDO";



export const setChartingValue = (chartType, tooth, index, value) => (dispatch, getState) => {
	dispatch({
		type: 'APP_SET',
		data: { blockRouting: true },
	});

	dispatch({
		type: CHARTING_SET_VALUE,
		chartType,
		tooth,
		index,
		value,
	});
};

export const setActiveChartOption = (option, value) => (dispatch, getState) => {
	dispatch({
		type: 'APP_SET',
		data: { blockRouting: true },
	});

	dispatch({
		type: CHARTING_SET_OPTION,
		option,
		value,
	});
};


export const saveDrawing = (canvas, data, subKey = null) => (dispatch, getState) => {
	dispatch({
		type: 'APP_SET',
		data: { blockRouting: true },
	});

	dispatch({
		type: CHARTING_SAVE_DRAWING,
		canvas,
		data,
		subKey,
	});
};


export const saveText = (canvas, data, subKey = null) => (dispatch, getState) => {
	dispatch({
		type: 'APP_SET',
		data: { blockRouting: true },
	});

	dispatch({
		type: CHARTING_SAVE_TEXT,
		canvas,
		data,
		subKey,
	});
};


export const saveShape = (canvas, data) => (dispatch, getState) => {
	dispatch({
		type: 'APP_SET',
		data: { blockRouting: true },
	});

	dispatch({
		type: CHARTING_SAVE_SHAPES,
		canvas,
		data,
	});
};


export const saveSymbols = (canvas, symbolType, data, subKey = null) => (dispatch, getState) => {
	dispatch({
		type: 'APP_SET',
		data: { blockRouting: true },
	});

	dispatch({
		type: CHARTING_SAVE_SYMBOLS,
		canvas,
		symbolType,
		data,
		subKey,
	});
};


export function hydrateChart(data) {
	return {
		type: CHARTING_HYDRATE,
		data,
	};
}


export function resetChart(data = {}) {
	return {
		type: CHARTING_RESET,
		data,
	};
}


export function setLayerVisibility(category, value) {
	return {
		type: CHARTING_SET_VISIBILITY,
		category,
		value,
	};
}


export const saveDiagnostics = (data) => (dispatch, getState) => {
	dispatch({
		type: 'APP_SET',
		data: { blockRouting: true },
	});

	dispatch({
		type: CHARTING_SAVE_DIAGNOSTICS,
		data,
	});
};


export const saveTreatments = (data) => (dispatch, getState) => {
	dispatch({
		type: 'APP_SET',
		data: { blockRouting: true },
	});

	dispatch({
		type: CHARTING_SAVE_TREATMENTS,
		data,
	});
};

export const undo = () => (dispatch, getState) => {
	dispatch({
		type: 'APP_SET',
		data: { blockRouting: true },
	});

	dispatch({
		type: CHARTING_UNDO,
	});
};


export const redo = () => (dispatch, getState) => {
	dispatch({
		type: 'APP_SET',
		data: { blockRouting: true },
	});

	dispatch({
		type: CHARTING_REDO,
	});
};