import { combineReducers } from 'redux';
import TitleBar from './Global/TitleBar';
import Auth from './Global/Auth';
import ChartingOptions from './Charting/ChartingOptions';
import ActiveChart from './Charting/ActiveChart';
import SnackBar from './Global/SnackBar';
import ContentHeight from './Global/ContentHeight';
import App from './Global/App';
import ErrorDialog from './Global/ErrorDialog';
import uploader from 'containers/ImageUploader/ImageUploader.redux';


const rootReducer = combineReducers({
	TitleBar,
	Auth,
	ChartingOptions,
	ActiveChart,
	SnackBar,
	ContentHeight,
	App,
	ErrorDialog,
	uploader,
});

export default rootReducer;