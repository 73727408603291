import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import {
	Home,
	InsertChart,
	Person,
	Pets,
	QuestionAnswer,
	Settings,
	SettingsApplications,
	Update,
} from '@material-ui/icons';
import { connect } from 'react-redux';
import buildInfo from 'buildNumber';



class SideNav extends PureComponent {


	checkAdmin = () => {
		let isAdmin = false;
		const { clinicID, clinics } = this.props;
		try {
			isAdmin = !!clinics[ clinicID ].admin;
		} catch(err) {
			isAdmin = false;
		}

		return isAdmin;
	};


	render() {
		const isAdmin = this.checkAdmin();

		return (
			<Drawer
				open={this.props.isOpen}
				onClose={this.props.closeMenu}
				onClick={this.props.closeMenu}
				style={{ width: 300 }}
			>
				<List>
					<Link to={'/'}>
						<ListItem button>
							<ListItemIcon>
								<Home />
							</ListItemIcon>
							<ListItemText primary="Home" />
						</ListItem>
					</Link>

					<Link to={'/faq'}>
						<ListItem button>
							<ListItemIcon>
								<QuestionAnswer />
							</ListItemIcon>
							<ListItemText primary="Beta FAQ" />
						</ListItem>
					</Link>

					<Link to={'/changelog'}>
						<ListItem button>
							<ListItemIcon>
								<Update />
							</ListItemIcon>
							<ListItemText primary="Changelog" />
						</ListItem>
					</Link>

					<Divider />

					<Link to={'/clients'}>
						<ListItem button>
							<ListItemIcon>
								<Person />
							</ListItemIcon>
							<ListItemText primary="Clients" />
						</ListItem>
					</Link>

					<Link to={'/patients'}>
						<ListItem button>
							<ListItemIcon>
								<Pets />
							</ListItemIcon>
							<ListItemText primary="Patients" />
						</ListItem>
					</Link>

					<Link to={'/reports'}>
						<ListItem button>
							<ListItemIcon>
								<InsertChart />
							</ListItemIcon>
							<ListItemText primary="Reports" />
						</ListItem>
					</Link>

					<Divider />

					{false &&
					<Link to={'/usersettings'}>
						<ListItem button>
							<ListItemIcon>
								<Settings />
							</ListItemIcon>
							<ListItemText primary="My Settings" />
						</ListItem>
					</Link>
					}


					{isAdmin &&
					<Link to={'/clinicsettings'}>
						<ListItem button>
							<ListItemIcon>
								<SettingsApplications />
							</ListItemIcon>
							<ListItemText primary="Clinic Settings" />
						</ListItem>
					</Link>
					}

					<Divider />


					<Link to={'/logout'}>
						<ListItem button>
							<ListItemIcon>
								<Person />
							</ListItemIcon>
							<ListItemText primary="Log Out" />
						</ListItem>
					</Link>

					<Divider />

					<ListItem>
						<ListItemText
							primary={`Version ${buildInfo.version} build ${buildInfo.build}`}
							primaryTypographyProps={{ variant: 'caption' }}
							secondary={buildInfo.date}
							secondaryTypographyProps={{ variant: 'caption' }}
						/>
					</ListItem>

				</List>
			</Drawer>
		);
	}
}



const mapStateToProps = state => ({
	clinicID: state.Auth.clinic,
	clinics: state.Auth.availableClinics,
});


export default connect(mapStateToProps)(SideNav);