import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import './index.css';
import { isIE } from 'react-device-detect';




if(
	document.readyState === "complete" ||
	(document.readyState !== "loading" && !document.documentElement.doScroll)
) {
	loadPage();
} else {
	document.addEventListener("DOMContentLoaded", loadPage);
}


function loadPage() {
	if(isIE) {
		ReactDOM.render(<div>Unfortunately, we are unable to support Internet Explorer. Please try another browser such
			as Chrome, Firefox, Edge, or Safari.</div>, document.getElementById('app'));
	} else {
		ReactDOM.render(<App />, document.getElementById('app'));
	}

	//registerServiceWorker();
}